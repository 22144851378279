import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import DangerBox1 from 'components/Web_User_Interface/720p_Series/Network/WiFi/DangerBox1';
import DangerBox2 from 'components/Web_User_Interface/720p_Series/Network/WiFi/DangerBox2';
import PrimaryBox from 'components/Web_User_Interface/720p_Series/Network/WiFi/PrimaryBox';
import WiFiTable from 'components/Web_User_Interface/720p_Series/Network/WiFi/wifiTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Network Menu // WiFi",
  "path": "/Web_User_Interface/720p_Series/Network/WiFi/",
  "dateChanged": "2017-12-08",
  "author": "Mike Polinowski",
  "excerpt": "Your WiFi connection should already be set up by WPS or by the Installation Wizard. Use the WiFi scanner to connect your camera to a different WiFi network. Or use the scan results to check the signal strength at the camera´s installation spot.",
  "image": "./WebUI_720p_SearchThumb_Network_WLAN.png",
  "social": "/images/Search/WebUI_720p_SearchThumb_Network_WLAN.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "720p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Network Menu // WiFi' dateChanged='2017-12-08' author='Mike Polinowski' tag='INSTAR IP Camera' description='Your WiFi connection should already be set up by WPS or by the Installation Wizard. Use the WiFi scanner to connect your camera to a different WiFi network. Or use the scan results to check the signal strength at the camera´s installation spot.' image='/images/Search/WebUI_720p_SearchThumb_Network_WLAN.png' twitter='/images/Search/WebUI_720p_SearchThumb_Network_WLAN.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/720p_Serie/Netzwerk/WLAN/' locationFR='/fr/Web_User_Interface/720p_Series/Network/WiFi/' crumbLabel="WiFi" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "720p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#720p-web-user-interface",
        "aria-label": "720p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`720p Web User Interface`}</h1>
    <h2 {...{
      "id": "network-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#network-menu",
        "aria-label": "network menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Network Menu`}</h2>
    <h3 {...{
      "id": "wifi",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#wifi",
        "aria-label": "wifi permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`WiFi`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <blockquote>
      <p parentName="blockquote">{`Your WiFi connection should already be set up by WPS or by the `}<a href="http://install.instar.de/" target="_blank" rel="noopener noreferrer">{`Installation Wizard`}</a>{`. Use the WiFi scanner to connect your camera to a different WiFi network. Or use the scan results to check the signal strength at the camera´s installation spot.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "716px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c95c2303397b14b7e8635405bc4e7b75/6bbf7/WebUI-Network_WLAN.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "104.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAAAsSAAALEgHS3X78AAADKklEQVQ4y41Uy1IaURTkq5L8R5JKsvBDXJpFrIp7sbQUH6VQxhIhlqLBNwIBBoRBXgPIMICKPHxvXHTSxwyCmEoWzWHu3Ntz+py+x+J2f8fbd+/x6vUbfPj4CX6/H0dHMaTSaaTTaaRSKYnPcXx8jGKxCKfTiYGBAYyPjwssq6urGB0dxZfhYdhs09A0DXlNw9XVFdrtNi4vLwWtVqsDrpvx/PwcExMT8Hq92NnZgcXhsGNhYQHLTie2t7dRqVSQzWQRP4p3ssjn87JerVYlGoYhkSCh2+1GNBoVWKYmJ/F1ZAQ2mw0bGxs4OTmBy+XC/Pw8pqamYLfbsfhtEYoSQTweRyAQwPr6Og4ODhAOhzuyQ6GQPFsODw+xu7srC4lEQrKg5GQyiXQqhVq1BkM3UNYN+d8tu9FoyH4SkiwSicDi/eHF56EhDA4OYm1tTTaXSiUUC0Xoui4HSicl6HpZ/jebzQ5pvV4X2WaGQqgmEvgZDCKTzUr63FSr1XB2diYZNJuNDgHJLi4u+rC8vPyUYaNeR6vZwsPDA+7v74WIhPxyrVaVrPgRkr+EPkK2mtZ59N+REJCUnTw9PZWs/kb2IuG0zQar1YrZ2VkhNgkJEj7KbnZiN14ktNsXMDMzA4fDIU2h1EKhgHK53Onkc7Luj/QR0n9jY1asuFziw3JZRyaTEdsw5nIactms3CA+sxRmgxippIdwaWkJzHJubg7rHg+qlYr4z3S+puXF7AQzZ0m6a8iG9RDGYjG5g/v7+0LAA4ZRRknXxY/0oq6XUKlUJfKq3dzc4Pr6WmKfZN5fj8cj9WOnaRn6UcvlRKYJOoCTJ5fLybShfEbWusfY/Nna2pLrpyiKNIWbORi6xxUPkJTvTLDOVLCysvJEeOjzYXNzU8YPLz4lEaqqIqooiEWjSKpJqAkV8XgCSVVFLBqTe8+SsI49kpkVa+jz+aSGlMz5d/7H3OaYomw+8z0ja91qt6XbPYSBgF/k7u3tSdo8YPqte6ia3nu+1tcUZhgOhWWB4Cbe6dvb23/i7u5OYk8NlYiCoD+IcCiCYDAoL1jL/wH3c57y2kpivwfsL//ms405K4k3AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c95c2303397b14b7e8635405bc4e7b75/e4706/WebUI-Network_WLAN.avif 230w", "/en/static/c95c2303397b14b7e8635405bc4e7b75/d1af7/WebUI-Network_WLAN.avif 460w", "/en/static/c95c2303397b14b7e8635405bc4e7b75/04ac8/WebUI-Network_WLAN.avif 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c95c2303397b14b7e8635405bc4e7b75/a0b58/WebUI-Network_WLAN.webp 230w", "/en/static/c95c2303397b14b7e8635405bc4e7b75/bc10c/WebUI-Network_WLAN.webp 460w", "/en/static/c95c2303397b14b7e8635405bc4e7b75/d8378/WebUI-Network_WLAN.webp 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c95c2303397b14b7e8635405bc4e7b75/81c8e/WebUI-Network_WLAN.png 230w", "/en/static/c95c2303397b14b7e8635405bc4e7b75/08a84/WebUI-Network_WLAN.png 460w", "/en/static/c95c2303397b14b7e8635405bc4e7b75/6bbf7/WebUI-Network_WLAN.png 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c95c2303397b14b7e8635405bc4e7b75/6bbf7/WebUI-Network_WLAN.png",
              "alt": "Web User Interface - 720p Series - Network WiFi",
              "title": "Web User Interface - 720p Series - Network WiFi",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <DangerBox1 mdxType="DangerBox1" />
    <EuiSpacer mdxType="EuiSpacer" />
    <WiFiTable mdxType="WiFiTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`If you cannot use the WPS service (`}<strong parentName="p">{`W`}</strong>{`ireless `}<strong parentName="p">{`P`}</strong>{`rotected `}<strong parentName="p">{`S`}</strong>{`etup) to integrate the camera into your local WiFi network - see included flyer Plug & Play for further information for the WPS function - click search to start the WLAN scanner. Just choose your own network and click “Join”. Now you just have to add the WiFi password (WLAN Key) and click submit to save the settings. Afterwards you can use the `}<strong parentName="p">{`Test`}</strong>{` function to verify your settings. Please disconnect the LAN cable and restart the camera (simply unplug the power supply for 3 seconds). The camera will automatically - within `}<strong parentName="p">{`max. 5 minutes`}</strong>{` - connect to your WiFi network. The cameras WiFi module will receive a new address from your router - in case you didn't assign a `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/Network/WiFi/"
      }}>{`Static IP Address`}</a>{` to your camera yet. You might have to use the INSTAR Camera Tool again to rediscover your camera. Your camera is now connected via WiFi!`}</p>
    <DangerBox2 mdxType="DangerBox2" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      